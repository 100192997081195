@import url(https://fonts.googleapis.com/css2?family=Roboto);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  margin: 0;
  padding: 0;
  outline: 0;
  font-family: "Roboto", sans-serif;
  text-align: center;
}
a:link,
a:visited {
  text-decoration: none;
  color: black;
}
.isHidden {
  display: none;
}
.AlertVisible {
  width: 200px;
  background-color: dodgerblue;
  position: fixed;
  color: white;
  padding: 10px;
  top: 50%;
  left: 42%;
  border: 2px solid white;
  border-radius: 5px;
}
.AlertVisible p {
  margin-bottom: 20px;
}
.AlertHidden {
  display: none;
}
.alertButtons {
  padding: 20px;
  cursor: pointer;
}
.notHidden {
  width: 60px;
  background-color: dodgerblue;
  position: fixed;
  color: white;
  padding: 10px;
  top: 50%;
  left: 48%;
  border: 2px solid white;
  border-radius: 5px;
}
body {
  height: 100vh;
  /* background-image: url(bg.jpg); */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
}
.login {
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 100%;
  height: 100%;
  background-image: url(/static/media/bg.94699f60.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.App {
  margin: 0 auto;
}
.siteLogo {
  width: 20px;
}
.pageTitleContainer {
  background-color: rgb(11, 21, 46);
  position: fixed;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  align-items: center;
  z-index: 10;
  top: 0;
  left: 0;
}
.tableInputs,
.tableInputsNotes {
  font-size: medium;
  border-color: lightgray;
  border-radius: 5px;
}
.tableInputsNotes {
  text-align: justify;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.tableInputs {
  text-align: end;
  width: 80px;
}
.tooltipWrapper {
  position: relative;
}

.tooltipWrapper:before {
  content: "CTR + click to copy event link";
  position: absolute;
  font-size: 0.6rem;
  top: 8px;
  width: 170px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  left: -10px;
  margin-left: 15px;
  padding: 5px;
  border: 1px solid white;
  border-radius: 10px;
  background: dodgerblue;
  color: #fff;
  text-align: center;
  display: none;
}
.tooltipWrapper:hover:before {
  display: block;
}
.betButton {
  border: 0;
  background: none;
  font-size: x-large;
}
.tableRow,
.tableBottom {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
}
.tableRowBets {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0 10px;
}
.tableRow {
  margin: 0 10px;
}
.TTLBets{
  margin: auto;
  font-size: xx-large;
  width: 100%;
}
.tooltip{
  color:white;
  position: relative;
  width: 75px;
  bottom: 0;
}
.calculations {
  width: 100%;
  display: flex;
  align-items: center;
  border: 1px solid lightgray;
  background-color: rgb(233, 233, 233);
}
.betsContainer {
  border: 1px solid black;
}
.tableBottom {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}
.tableBottom span {
  min-width: 60px;
  padding: 5px;
  margin: 10px;

  border-radius: 5px;
}
.doubleCell {
  display: flex;
  margin: 0 10px;
  flex-direction: column;
}
.pageTitle {
  width: 100%;
  color: white;
  font-size: x-large;
  text-transform: uppercase;
}
.MatchBets img {
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}
.MatchHeader {
  border-bottom: 1px solid black;
}
.matchInputName {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border: 0;
  font-size: larger;
  padding: 5px;
  padding-bottom: 0;
  margin-bottom: 2px;
}
.darckBackground {
  background-color: rgb(11, 21, 46);
  padding: 5px;
  color: white;
}
.searchBar {
  margin: 10px 0;
  width: 30%;
  height: 50px;
  background-color: #fafafa;
  border-radius: 4px;
  text-transform: uppercase;
  font-size: 1.2rem;
  text-align: center;
  color: black;
}
.HighestOdds {
  margin: 0 20px;
  border-radius: 4px;
  min-height: 50px;
  margin: 0 auto;
  text-transform: uppercase;
}
.eventsContainer {
  margin-top: 100px;
  border-radius: 20px;
  overflow: hidden;
}

.betSitesContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.container {
  padding: 10px;
  margin: 50px 0px;
  width: 22%;
  background-color: #fafafa;
  border-radius: 4px;
  text-transform: uppercase;
  text-align: center;
}

.title-box {
  margin: 25px 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  font-size: 1rem;
}
.Match {
  background-color: rgba(255, 255, 255, 0.7);
}
.selectedMatch {
  background-color: aquamarine;
}
.matchName {
  font-size: 1rem;
  font-weight: 1000;
}

.categories {
  font-size: 0.8rem;
}
.percentInput,
.daysInput {
  border: 0;
  text-transform: uppercase;
}
.percentInput {
  width: 60px;
}
.awayName,
.homeName {
  font-size: 1rem;
  font-weight: 1000;
}
.VSIcon {
  height: 54px;
  width: 54px;
}
.team {
  width: 100px;
}
.team img {
  height: 54px;
  width: 54px;
}
.match-tile {
  position: relative;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  margin: 10px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}
.match-tile img {
  width: 52px;
  height: 52px;
}
.match-tile p {
  font-size: 1rem;
}

.odd_type {
  font-size: 0.8rem;
}

.odd_name {
  width: 100%;
  text-align: left;
}
.odd_coef {
  position: relative;
  right: 0px;
  font-size: 0.8rem;
  font-weight: 1000;
}
.match-tile .team {
  width: 100px;
}

.oddNameContainer {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  background-color: white;
}
.oddNameContainer span {
  margin: 0 28px;
}
.matchContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.oddsValueContainer {
  display: flex;
}
.odds {
  margin: 0 5px;
  padding: 10px 5px;
  background-color: white;
  border-radius: 5px;
  width: 60px;
  cursor: pointer;
}
.teamsNameContainer {
  margin: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.oddsContainer {
  margin: 20px 20px;
  display: flex;
  background-color: rgba(219, 219, 219, 0.6);
  border-radius: 5px;
  width: 60px;
  max-height: 80px;
  flex-direction: column;
  align-items: center;
}

.timeAndNameContainer {
  display: flex;
  align-items: center;
}
.teamLogo {
  width: 20px;
  margin: 0 10px;
}
.dateContainer {
  width: 81px;
  padding-left: 10px;
}
.oddsAndLogoContainer {
  min-height: 50px;
  background-color: red;
}
.spiner {
  width: 40px;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.bookieLogo {
  margin: 0 auto;
  padding: 0 5px;
  width: 55px;
  height: 30px;
}
.MatchBookies {
  display: flex;
  background: rgba(0, 143, 208, 0.2);
  height: 50px;
}
.MatchBets {
  display: flex;
  flex-direction: column;
}
.buttonMore {
  cursor: pointer;
  font-size: small;
  padding: 0 10px;
  z-index: 200;
}
.timeContainer {
  font-size: x-large;
  font-weight: bolder;
  margin-left: 10px;
}
button {
  background-color: white;
  margin: 5px;
  border-radius: 5px;
  border-width: 0;
  padding: 3px;
  cursor: pointer;
}
.bookieLogoVertical {
  margin: 5px;
  width: 75px;
  height: 40px;
}

.box {
  margin: 200px auto;
  width: 40px;
  height: 140px;
  position: relative;
}
.shadow {
  position: absolute;
  width: 100%;
  height: 10px;
  background-color: grey;
  bottom: 0;
  border-radius: 100%;
  -webkit-transform: scaleX(0.8);
          transform: scaleX(0.8);
  opacity: 0.6;
  -webkit-animation: shadowScale 1s linear infinite;
          animation: shadowScale 1s linear infinite;
}
.gravity {
  width: 40px;
  height: 40px;
  -webkit-animation: bounce 1s cubic-bezier(0.68, 0.35, 0.29, 0.54) infinite;
          animation: bounce 1s cubic-bezier(0.68, 0.35, 0.29, 0.54) infinite;
}
.ball {
  width: 40px;
  height: 40px;
  background: white;
  border-radius: 50%;
  background-image: url("https://openclipart.org/download/183228/1378699847.svg");
  background-size: 84px;
  background-position: center;
  background-repeat: no-repeat;
  -webkit-animation: roll 0.6s linear infinite;
          animation: roll 0.6s linear infinite;
}

@-webkit-keyframes roll {
  0% {
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes roll {
  0% {
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@-webkit-keyframes bounce {
  0% {
  }
  50% {
    -webkit-transform: translateY(100px);
            transform: translateY(100px);
  }
  100% {
  }
}
@keyframes bounce {
  0% {
  }
  50% {
    -webkit-transform: translateY(100px);
            transform: translateY(100px);
  }
  100% {
  }
}
@-webkit-keyframes shadowScale {
  0% {
  }
  50% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    opacity: 0.8;
  }
  100% {
  }
}
@keyframes shadowScale {
  0% {
  }
  50% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    opacity: 0.8;
  }
  100% {
  }
}

.topButton {
  position: fixed;
  height: 35px;
  color: white;
  background-color: cornflowerblue;
  right: 20px;
  bottom: 20px;
  font-size: 1rem;
  z-index: 1000;
}

