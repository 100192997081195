.box {
  margin: 200px auto;
  width: 40px;
  height: 140px;
  position: relative;
}
.shadow {
  position: absolute;
  width: 100%;
  height: 10px;
  background-color: grey;
  bottom: 0;
  border-radius: 100%;
  transform: scaleX(0.8);
  opacity: 0.6;
  animation: shadowScale 1s linear infinite;
}
.gravity {
  width: 40px;
  height: 40px;
  animation: bounce 1s cubic-bezier(0.68, 0.35, 0.29, 0.54) infinite;
}
.ball {
  width: 40px;
  height: 40px;
  background: white;
  border-radius: 50%;
  background-image: url("https://openclipart.org/download/183228/1378699847.svg");
  background-size: 84px;
  background-position: center;
  background-repeat: no-repeat;
  animation: roll 0.6s linear infinite;
}

@keyframes roll {
  0% {
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes bounce {
  0% {
  }
  50% {
    transform: translateY(100px);
  }
  100% {
  }
}
@keyframes shadowScale {
  0% {
  }
  50% {
    transform: scaleX(1);
    opacity: 0.8;
  }
  100% {
  }
}
